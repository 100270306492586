<template>
    <div>
        <!-- <div :class="'exportStatus ' + ((export_status.data.progress !== 0) && 'active')">
			<a-alert
				class="alert"
				message="您当前存在正在导出的任务"
				:description="export_status.data.type"
				show-icon
			>
				<template #icon>
					<a-progress :width="40" class="progress" type="circle" :percent="export_status.data.progress" />
				</template>
			</a-alert>
		</div> -->
        <TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
            :slot_table_list="['status']" ref="list">
            <template slot="left_btn">
                <a-button style="margin-right:10px" @click="update_department()" type="primary">
                    更新组织架构</a-button>
                <a-button @click="pu_distributor" type="primary" style="margin-right: 10px;">同步分销商</a-button>
                <a-button @click="pu_seller" type="primary" style="margin-right: 10px;">同步经销商</a-button>
                <a-button @click="pu_tray" type="primary" style="margin-right: 10px;">同步托盘</a-button>
                <a-button @click="pu_ware" type="primary" style="margin-right: 10px;">同步仓库</a-button>
                <a-button @click="pu_carrier" type="primary" style="margin-right: 10px;">同步承运商</a-button>
                <a-button @click="pu_dealer_ware" type="primary" style="margin-right: 10px;">同步经销商仓库</a-button>
                <a-button class="float_left" @click="Synchronize()" type="primary">同步商品</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <!-- 同一时间只会有一条报表正在导出中 -->
                <a-tag :color="(data.text <= 0) ? config.color.error : config.color.primary">
                    {{getDataStatusValue(data.record.status)}}</a-tag>
            </template>
        </TableList>

    </div>
</template>

<script>
    import {
        message
    } from 'ant-design-vue';
    import {
        getSystemTaskList,
    } from "@/api/export";
    import {
        updateDepartment
    } from "@/api/user";
    import TableList from "@/components/TableList";
    import {
        push_seller,
        push_distributor,

    } from "@/api/customer";
    import {
        pushTrayInfo,
        push_ware,
        pushSellerStorage,
        push_carrier
    } from '@/api/push'
    import {
        syncDrpGoodsInfo,
    } from "@/api/goods";
    let status_list = [{
            key: -1,
            value: '执行失败'
        },
        {
            key: 0,
            value: '未执行'
        },
        {
            key: 1,
            value: '执行中'
        },
        {
            key: 2,
            value: '执行成功',
        },
    ];
    const columns = [{
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "任务名称",
            width: 170,
            dataIndex: "name",
        },
        {
            title: "执行人",
            dataIndex: "username",
        },
        {
            title: "任务状态",
            dataIndex: "status",
            scopedSlots: {
                customRender: "status"
            }
        },
        {
            title: "任务耗时",
            dataIndex: "time",
        },
        {
            title: "开始时间",
            dataIndex: "start_time",
        },
        {
            title: "创建时间",
            dataIndex: "create_time",
        },
        {
            title: "完成时间",
            dataIndex: "end_time",
        },
    ];


    export default {
        name: "Index",
        components: {
            TableList,
        },
        data() {
            return {
                config: this.$config,
                get_table_list: getSystemTaskList,
                columns,
                status_list,
                form_data_seo: {
                    list: [{
                        type: "text",
                        name: "keyword",
                        title: "任务名称",
                        placeholder: ' 请输入',
                        mode: "default",
                        options: {},
                        list: []
                    }, ],
                    ...this.$config.form_data_seo
                },
            };
        },


        async created() {},
        methods: {

            //处理任务状态
            getDataStatusValue(status) {
                for (let i = 0; i < this.status_list.length; i++) {
                    if (this.status_list[i].key == status) {
                        return this.status_list[i].value
                    }
                }
            },
            update_department() {
                message.loading('正在请求...', 2).then(() => {
                    this.$refs.list.get_list();
                })
                updateDepartment({
                    info: true
                }).then(() => {

                });
            },
            pu_distributor() {
                message.loading('正在请求...', 2).then(() => {
                    this.$refs.list.get_list();
                })
                push_distributor({
                    data: {},
                    // info: true
                })
            },

            pu_seller() {
                message.loading('正在请求...', 2).then(() => {
                    this.$refs.list.get_list();
                })
                push_seller({
                    data: {},
                    // info: true
                })
            },
            pu_tray() {
                message.loading('正在请求...', 2).then(() => {
                    this.$refs.list.get_list();
                })
                pushTrayInfo()
            },
            pu_ware() {
                message.loading('正在请求...', 2).then(() => {
                    this.$refs.list.get_list();
                })
                push_ware()
            },
            pu_carrier() {
                message.loading('正在请求...', 2).then(() => {
                    this.$refs.list.get_list();
                })
                push_carrier()
            },
            pu_dealer_ware() {
                message.loading('正在请求...', 2).then(() => {
                    this.$refs.list.get_list();
                })
                pushSellerStorage()
            },
            Synchronize() {
                message.loading('正在请求...', 2).then(() => {
                    this.$refs.list.get_list();
                })
                syncDrpGoodsInfo({
                    data: {},
                })
            },
        }
    };
</script>

<style lang="less">
    @import url("../../assets/less/app.less");

    .exportStatus {
        transition: all 0.2s ease;
        height: 0;
        overflow: hidden;

        .alert {
            padding-left: 75px;
        }
    }

    .active {
        height: 85px;
        margin: 15px;
        margin-bottom: -8px;
    }

    .num_text {
        zoom: 0.8;
        position: absolute;
        width: 100%;
        text-align: center;
        left: -26px;
        top: -8px;
        z-index: 1;
    }
</style>